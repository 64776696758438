import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilesService } from 'src/app/@shared/services/files.service';
import { ModalComponent } from '../modal/modal.component';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-media-viewer',
  templateUrl: './media-viewer.component.html',
  styleUrls: ['./media-viewer.component.sass'],
})
export class MediaViewerComponent implements OnInit {
  @Input()
  data: any;
  videoBlob: any;
  videoBlobUrl: any;
  constructor(public dialog: MatDialog, private _fileService: FilesService) {}

  ngOnInit() {
    this._fileService.getProxyUrl(this.data.data.id).subscribe(response => {
      this.videoBlob = response;
      this._fileService.blobToBase64(this.videoBlob).then(image => {
        this.videoBlobUrl = image.changingThisBreaksApplicationSecurity;
      });
    });
  }

  goBack() {
    if(this.data.close) {
      this.dialog.closeAll();
    }
  }

  async download() {
    await lastValueFrom(this._fileService.downloadDirectory(this.data.data?.id));
    this._fileService.downloadFile(this.data.data.title, this.videoBlob);
  }
  openModal() {
    const file = this.data.data.actions.choices.share[0];

    const dialogRef = this.dialog?.open(ModalComponent, {
      width: '636px',
      data: {
        action: 'share-permission',
        title: file.name,
        id: file.id,
        data: [...file.userGroups, ...file.users] || [],
      },
    });
  }
}
