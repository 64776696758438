import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpEventType,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { FilesService } from '../services/files.service';

@Injectable()
export class UploadInterceptor implements HttpInterceptor {
  rep: any = {};
  constructor(private _filesService: FilesService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.rep = req;

    if (req.reportProgress) {
      // only intercept when the request is configured to report its progress
      return next.handle(req).pipe(
        tap(
          (event: HttpEvent<any>) => {
            if (event?.type === HttpEventType.DownloadProgress) {
              // here we get the updated progress values, call your service or what ever here

              const percent = Math.round(
                (event.loaded / (event.total ?? 100)) * 100
              );

              console.log({body: this.rep.body})
              if (this.rep.body?.type == 'file') {
                this._filesService.updateProgress(this.rep.body.index, percent);
              } else if (!this.rep.body?.type) {
                this._filesService.updateProgress(-1, percent);
              }
            } else if (event?.type === HttpEventType.Response) {
              
            }
          },
          (error) => {
            

            const mError = error.error.error;
            
            let errorMsg = '';
            if (mError.includes('This name is already taken')) {
              errorMsg = 'File name already exists';
            } else if(errorMsg.includes('Payload Too Large')) {
              errorMsg = 'File size is too large';
            } else {
              errorMsg = 'File type is invalid';
            }

            this._filesService.updateProgress(-1, -1, errorMsg);
          }
        )
      );
    } else {
      return next.handle(req);
    }
  }
}
