<ng-container>
  <div class="container">
    <div class="body margin-container">
      <div class="recents {{ userType }}">
        <div class="statistics">
          <app-progress-card
            [image]="'../../../assets/images/icons/file-icon.png'"
            [title]="'All Documents'"
            [number]="dashData.allDocuments"
            [location]="'documents'"
            (filterWorkflowDataEvent)="filterWorkflowData(filterEnum.all)"
          ></app-progress-card>
          <app-progress-card
            [image]="'../../../assets/images/icons/drafts-icon.svg'"
            [title]="'Viewed'"
            [number]="dashData.viewed"
            [location]="'viewed'"
            (filterWorkflowDataEvent)="filterWorkflowData(filterEnum.viewed)"
          ></app-progress-card>
          <app-progress-card
            [image]="'../../../assets/images/icons/pending-icon.svg'"
            [title]="'Pending'"
            [number]="dashData.pending"
            [location]="'pending'"
            (filterWorkflowDataEvent)="filterWorkflowData(filterEnum.pending)"
          ></app-progress-card>
          <app-progress-card
            [image]="'../../../assets/images/icons/signed-icon.svg'"
            [title]="'Signed'"
            [number]="dashData.signed"
            [location]="'signed'"
            (filterWorkflowDataEvent)="filterWorkflowData(filterEnum.signed)"
          ></app-progress-card>
          <app-progress-card
            [image]="'../../../assets/images/icons/completed-icon.svg'"
            [title]="'Completed'"
            [number]="dashData.completed"
            [location]="'completed'"
            (filterWorkflowDataEvent)="filterWorkflowData(filterEnum.completed)"
          ></app-progress-card>
        </div>
  
        <div  class="recents {{ userType }}">
        <app-tablet-table
          *ngIf="isTabletWidth || isPhoneWidth"
          [itemTemplate]="signsecure_document_row"
          [title]="signSecureDocumentTitle"
          [data]="signSecureDocumentData"
        >
          <button *ngIf="isTabletWidth" class="button" (click)="useSignSecure()">
            <img [src]="'../../../../assets/images/signature-icon.svg'" alt="" />
            Use SignSecure
          </button>
        </app-tablet-table>
  
        <div class="recent-admin">
          <app-tablet-table
            *ngIf="isTabletWidth || isPhoneWidth"
            [itemTemplate]="recent_document_row"
            [title]="recentDocmentTitle"
            [data]="recentDocumentData | async"
          >
          </app-tablet-table>

          <app-tablet-table
            *ngIf="userType === 'admin' && (isTabletWidth || isPhoneWidth)"
            [itemTemplate]="recent_user_row"
            [title]="recentUsersTitle"
            [data]="recentUsersData"
          >
          </app-tablet-table>

          <app-tablet-table
            *ngIf="(isTabletWidth || isPhoneWidth) && userType === 'user'"
            [itemTemplate]="recent_folder_row"
            [title]="'Recent Folders'"
            [data]="recentFolders"
          >
          </app-tablet-table>
        </div>

        <app-table
          *ngIf="!isTabletWidth && !isPhoneWidth"
          [title]="signSecureDocumentTitle"
          [data]="signSecureDocumentData"
          [columns]="signSecureDocumentCols"
          [sortColumns]="signSecureDocumentSortCols"
          [canOpenFile]="true"
          [type]="'recent-documents'"
          (modalCloses)="recentDocumentModal($event)"
          [rowFunction]="openSignSecureDoc"
          [paginate]="true"
          [addButton]="'Use SignSecure'"
          [addButtonIcon]="'../../../../assets/images/signature-icon.svg'"
          (modal)="useSignSecure()"
          [pageSize]="5"
          class="signsecure"
        >
        <div class="tableSearch">
          <div class="search-form">
            <mat-form-field floatLabel="never" appearance="fill">
              <input
                matInput
                type="text"
                placeholder="Input Your Text in Here"
                [(ngModel)]="searchQuery"
                (keydown.enter)="search()"
              />
              <span matPrefix
                ><img
                  class="search-image"
                  src="../../../assets/images/icons/search.png"
                  alt=""
              /></span>
            </mat-form-field>
            <button type="submit" class="search-button" (click)="search()">
              Search
            </button>
          </div>
        </div>
        </app-table>
  
        </div>
        <div class="recent-admin" *ngIf="!isTabletWidth && !isPhoneWidth">

          <app-table
            [title]="recentDocmentTitle"
            [data]="recentDocumentData | async"
            [columns]="recentDocumentCols"
            [sortColumns]="recentDocumentSortCols"
            [canOpenFile]="true"
            [type]="'recent-documents'"
            (modalCloses)="recentDocumentModal($event)"
            [nameFunction]="openPdf"
            style="flex-grow: 1"
            [customizeMatCellClass]="'customize-mat-cell'"
          ></app-table>
          <app-table
            *ngIf="userType === 'admin'"
            [title]="recentUsersTitle"
            [data]="recentUsersData"
            [columns]="recentUsersCols"
            style="flex-grow: 1"
            [customizeMatCellClass]="'customize-mat-cell'"
          ></app-table>
        </div>

        <app-table
          *ngIf="!isTabletWidth && !isPhoneWidth && userType === 'user'"
          [title]="'Recent Folders'"
          [data]="recentFolders"
          [columns]="recentFoldersCols"
          [rowFunction]="openFolder"
          (modalCloses)="recentDocumentModal($event)"
          style="flex-grow: 1"
        ></app-table>
      </div>
      
    </div>
    <div class="bottom-row" *ngIf="!isTabletWidth && !isPhoneWidth"> 
      <app-dashboard-banner></app-dashboard-banner>
    </div>
  </div>
</ng-container>

<ng-template #signsecure_document_row let-data>
  <div class="recent-document-row" (click)="openSignSecureDoc('', data)">
    <div class="left-content">
      <div class="recent-document__content">
        <p class="title">{{ data.name }}</p>
        <p class="subtitle">{{ data.status }}</p>
        <p class="content">
          {{
            data.updatedAtFormatted || data.updatedAt | date : "MMM d, y 'at' h:mm a"
          }}
        </p>
      </div>
    </div>

    <div class="recent-document__img" >
      <img src="../../../../assets/images/arrow-right.svg" alt="" srcset="" />
    </div>
  </div>
</ng-template>

<ng-template #recent_document_row let-data>
  <div class="recent-document-row" (click)="openPdf(data)">
    <div class="left-content">

      <div class="recent-document__content">
        <p class="title">{{ data.name }}</p>
        <mat-chip-list class="list-tags">
          <mat-chip
            class="chip-tag"
            *ngFor="let tag of data.tags; let i = index"
            >{{ tag.name }}
          </mat-chip>
        </mat-chip-list>
        <!-- <pre class="subtitle">{{ data | json }} </pre> -->
        <p class="content">
          {{
            data.date | date : "MMM d, y 'at' h:mm a"
          }}
        </p>
      </div>
    </div>

    <div class="recent-document__img" >
      <img src="../../../../assets/images/arrow-right.svg" alt="" srcset="" />
    </div>
  </div>
</ng-template>

<ng-template #recent_folder_row let-data>
  <div class="recent-document-row" (click)="openFolder('', data)">
    <div class="left-content">

      <div class="recent-document__content">
        <p class="title">{{ data.name }}</p>
        <mat-chip-list class="list-tags">
          <mat-chip
            class="chip-tag"
            *ngFor="let tag of data.tags; let i = index"
            >{{ tag.name }}
          </mat-chip>
        </mat-chip-list>
        <!-- <pre class="subtitle">{{ data | json }} </pre> -->
        <p class="content">
          {{
            data.date | date : "MMM d, y 'at' h:mm a"
          }}
        </p>
      </div>
    </div>

    <div class="recent-document__img" >
      <img src="../../../../assets/images/arrow-right.svg" alt="" srcset="" />
    </div>
  </div>
</ng-template>

<ng-template #recent_user_row let-data>
  <div class="recent-document-row">
    <div class="left-content">
      <div class="recent-document__content">
        <p class="title">{{ data.name }}</p>
        <p class="subtitle1">{{ data.email }}</p>
        <p class="content">
          <a>Added on: </a
          >
          {{ data.date_added | date : "MMM d, y 'at' h:mm a" }} 
        </p>
      </div>
    </div>
    <div class="recent-document__img" >
      <img src="../../../../assets/images/arrow-right.svg" alt="" srcset="" />
    </div>
  </div>
</ng-template>