<div class="container">
  <h4 *ngIf="ocrAction=='run-ocr'" class="title">Run OCR on "{{ title }}"</h4>
  <h4 *ngIf="ocrAction=='edit-ocr'" class="title">Edit OCR on "{{ title }}"</h4>
  <div class="ocr-text">
    <mat-form-field class="text-form" floatLabel="never" appearance="fill">
      <textarea
        class="textarea-resize"
        matInput
        [(ngModel)]="ocrResult"
        disabled="false"
      ></textarea>
    </mat-form-field>
  </div>
  <div class="btnGrp">
    <button matRipple class="cancel" (click)="closeModal()">Discard Changes</button>
    <button matRipple class="save" (click)="saveOcr()">Save</button>
  </div>
</div>
