import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faUser, faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { faDownload, faUsers, faLock } from '@fortawesome/free-solid-svg-icons';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { Actions } from '../models/Actions.model';
import { FilesService } from '../services/files.service';
import { lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { BreadcrumbsService } from '../services/breadcrumbs.service';
@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.sass'],
})
export class ActionsComponent implements OnInit {
  @Input()
  actions: Actions = {};
  @Input() type: string = '';
  @Input() callback: any = () => {};
  @Input() data: any;
  @Input() disable: boolean = false;
  @Input() containerClass: string | undefined;
  @Output() modifyFileEvent = new EventEmitter();
  @Output() closeModalEvent = new EventEmitter();

  faDownload = faDownload;
  faUsers = faUsers;
  faUser = faUser;
  faLock = faLock;
  faCalendarCheck = faCalendarCheck;

  constructor(
    public dialog: MatDialog,
    private _fileService: FilesService,
    @Inject(DOCUMENT) private _document: Document,
    private router: Router,
  ) {
    this.dialog = dialog;
  }

  ngOnInit(): void {}

  showModal(action: string, size: string, data?: any) {
    action = this.type + '-' + action;
    const dialogRef = this.dialog?.open(ModalComponent, {
      width: size,
      data: { action, data },
    });

    dialogRef?.afterClosed()?.subscribe(result => {
      this.callback.emit(result);
    });
  }

  fileActionModal(action: string, size: string, data?: any) {
    this.dialog?.closeAll()
    action = action;
    const dialogRef = this.dialog?.open(ModalComponent, {
      width: size,
      data: { action, data },
    });

    dialogRef?.afterClosed()?.subscribe(result => {
      this.callback.emit(result);
    });
  }
  modifyFile(data: any) {    
    this.closeModal()
    this._fileService.title = this.data.name;
    this._fileService.imgSrc = this.data.fileType;
    this.router.navigateByUrl(`folders-and-files/${data.id}}`)
    this.modifyFileEvent.emit(data);
    
  }

  /**
   * TODO ::: Need to request for duplicate directory endpoint
   */
  createDuplicateDirectory() {
    const newDirectory = { ...this.data };
    newDirectory.name = newDirectory.title + ' - Copy';
    newDirectory.type = newDirectory.file_type;
    newDirectory.file = {};
    delete newDirectory.actions;
    delete newDirectory.file_type;
    this._fileService.duplicateDirectory(newDirectory);
  }

  async download(data: any, path: string = 'path') {
    await  lastValueFrom(this._fileService.downloadDirectory(data?.id))
    if (data?.type === 'folder') {
      this._fileService.downloadFolder(data.id).subscribe((response: any) => {
        const blob = new Blob([response], { type: 'application/zip' });
        const blobUrl = URL.createObjectURL(blob);
        const link = this._document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', `${data.name}.zip`);
        this._document.body.appendChild(link);
        link.click();
        link.remove();
      });
    } else {
      if (data.fileExtension == 'pdf') {
        let pdfSrc: any;
        await lastValueFrom(this._fileService.getProxyUrl(data.id)).then(
          (pdfSrcData) => {
            
            const fileReader = new FileReader();
            fileReader.onload = () => {
              
              pdfSrc = new Uint8Array(fileReader.result as ArrayBuffer);
              
            };
            fileReader.onloadend = () => {
              
              this._fileService.downloadPDF(data.name, pdfSrc);
            };
            fileReader.readAsArrayBuffer(pdfSrcData);
          }
        );
      }else{
        let imagePath:any;
        await lastValueFrom(this._fileService.getProxyUrl(data.id)).then((response)=>{
          imagePath = response;}
     );
        this._fileService.downloadFile(data.name, imagePath);
      }
    }
  }

  shareFile(data: any) {
    this.dialog?.closeAll()
    const file = data.length === 1 ? data[0] : data;
    const dialogRef = this.dialog?.open(ModalComponent, {
      width: '636px',
      data: {
        action: 'share-permission',
        title: file.name,
        fileId: file.id,
        fileType: file.type,
        fileExtension: file.fileExtension,
        data: [...file.userGroups, ...file.users],
      },
    });

    dialogRef?.afterClosed()?.subscribe(result => {
      this.callback.emit(result);
    });
  }
  showModalMenu(data: any) {
    this.modifyFileEvent.emit(data.choices?.modify);
    const title = data.choices?.modify.name
    const dialogRef = this.dialog?.open(ModalComponent, {
      width: '636px',
      panelClass: 'bottom-modal',
      data: {
        action: 'actions',
        title,
        data: data,
      },
    });

    dialogRef?.afterClosed()?.subscribe(result => {
      this.callback.emit(result);
    });
  }

  moveFile(data: any) {
    this.dialog?.closeAll()
    const dialogRef = this.dialog?.open(ModalComponent, {
      width: '636px',
      data: {
        action: 'move-file',
        title: data?.name || data?.title,
        fileId: data.id,
        data: [data],
      },
    });
  }

  closeModal() {
    this.closeModalEvent.emit();
  }
}
