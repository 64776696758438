import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { Subscription, catchError, lastValueFrom, switchMap } from 'rxjs';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { Breadcrumb } from '../models/breadcrumb.model';
import { FileDataModel } from '../models/file-data.model';
import { BreadcrumbsService } from '../services/breadcrumbs.service';
import { FileActionService } from '../services/file-action.service';
import { FilesService } from '../services/files.service';
import { LoaderService } from '../services/loader.service';
import { UserGroupService } from '../services/user-group.service';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication.service';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'app-file-view',
  templateUrl: './file-view.component.html',
  styleUrls: ['./file-view.component.sass'],
})
export class FileViewComponent implements OnInit, OnDestroy {
  @Output() goBackEvent = new EventEmitter<boolean>();
  loc1: string = 'Legal';
  loc2: string = 'Documnents';
  loc3: string = 'Santiago v. Commission on Elections';
  tags: any[] = ['testing@gmail.com'];
  userGroups: any[] = [];
  fileData: FileDataModel = { id: '', src: '', title: '', size: 0 };
  ocrResult: string = '';
  file: any = { name: '' };

  name: string = '';
  userGroupList: any[] = [];
  userList: any[] = [];
  breadcrumbs: Breadcrumb[] = [];
  dateVal: any;
  dueDateVal: any;
  documentNumber: string = '';
  docDetails: string = '';
  isPublished: boolean = false;
  isFolder: boolean = false;
  fileId: string = '';
  sub: Subscription = new Subscription();
  today = new Date();
  constructor(
    public dialog: MatDialog,
    private _fileService: FilesService,
    private _fileActionService: FileActionService,
    private _breadcrumbsService: BreadcrumbsService,
    private dp: DatePipe,
    private _snackBar: MatSnackBar,
    private _route: ActivatedRoute,
    private _loader: LoaderService,
    private _location: Location,
    private changeDetection: ChangeDetectorRef,
    private _auth: AuthenticationService,
    private _navigation: NavigationService
  ) {}

  initData() {
    this.isPublished = this.file.workflow ? true : this.file?.isPublished;
    this.isFolder = this.file.type == 'folder';
    this.fileId = this.file?.id;
    this.ocrResult = this.file?.ocr;
  }

  loadFile() {
    this.file = this._fileActionService.file;
    if (!!!this.file) {
      this.sub.add(
        this._route.params
          .pipe(
            switchMap(({ id }) => {
              this._loader.show();
              return this._fileService.getFile(id);
            }),
            catchError(error => {
              console.log({ error });
              if (error instanceof HttpErrorResponse) {
                if (this._auth.isLogin()) {
                  this._navigation.goBack();
                } else {
                  this._navigation.goTologinPage();
                }
              }
              return error;
            })
          )
          .subscribe(({ data }: any) => {
            this.isPublished = data.workflow ? true : data?.isPublished;
            console.log({ data });
            this.isFolder = data.type == 'folder';
            this.file = data;
            this.initData();
            this._loader.hide();
          })
      );
    } else {
      this.initData();
    }
  }

  ngOnDestroy(): void {
    this._breadcrumbsService.removeBreadcrumbData();
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.loadFile();
    this.tags = this.file?.tags ? this.file.tags : [];
    this._breadcrumbsService.setBreadcrumbsData({
      title: this.file.name,
      id: '',
    });
    this._breadcrumbsService.breadcrumbs$.subscribe(i => {
      this.breadcrumbs = i;
    });
    this.userGroups = this.file.userGroups;
    this.userList = this.file.users;
    //this.userGroups = this.file.shareToTeams;
    //this.userList = this.file.shareToUsers;
    this.name = this.file.name;
    this.documentNumber = this.file.workflow
      ? this.file.id
      : this.file.documentNumber;
    this.docDetails = this.file.description; //this.file.workflow ? this.file.description : this.file.details;
    this.dateVal = new Date(this.file.dateFormatted.split(',')[0]);
    console.log(this.file);
    this.dueDateVal = this.file.dueDateFormatted
      ? new Date(this.file.dueDateFormatted.split(',')[0])
      : '';
  }

  showModal() {
    const dialogRef = this.dialog?.open(ModalComponent, {
      width: '636px',
      data: {
        action: 'share-permission',
        title: this.name,
        fileId: this.file.id,
        data: [...this.userGroups, ...this.userList] || [],
      },
    });

    dialogRef?.afterClosed()?.subscribe(result => {
      if (result) {
        if (!result.cancelled) {
          this.userGroups = result.userGroups;
          this.userList = result.users;
        }
      }
    });
  }

  addTags(tag: any) {
    const { value } = tag;
    if (tag.value !== '') {
      if (!this.tags.some(tag => tag === value)) {
        this.tags.push(value);
      }
      tag.value = '';
    }
  }

  tagUpdate(event: any) {}

  getObjectImage(fileType: string): string {
    switch (fileType) {
      case 'file':
        return '../../../assets/images/icons/file.png';
      case 'folder':
        return '../../../assets/images/icons/folder.png';
      default:
        return '../../../assets/images/icons/file.png';
    }
  }
  goBack() {
    this.goBackEvent.emit(false);
    this._location.back();
  }

  async updateDirectory() {
    const updatedFile = { ...this.file };
    const date = this.dp.transform(
      new Date(this.dateVal),
      "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
    );

    if (this.dueDateVal) {
      const dueDateObj = new Date(this.dueDateVal);
      dueDateObj.setHours(23, 59, 59, 999);
      const dueDate = this.dp.transform(
        dueDateObj,
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
      );

      updatedFile.dueDate = dueDate;
      updatedFile.dueDateFormatted = '';
    }
    updatedFile.name = this.name;
    updatedFile.userGroups = this.userGroups.map(groups => groups.id);
    updatedFile.tags = this.tags.map(tag => {
      return tag.name ? tag : { name: tag, type: 'normal' };
    });
    updatedFile.documentNumber = this.documentNumber;
    updatedFile.details = this.docDetails;
    updatedFile.date = date;
    updatedFile.size = updatedFile.size || '0 KB';
    updatedFile.description = this.docDetails;
    updatedFile.documentsDetails = this.docDetails;

    await this._fileService.updateDirectory(updatedFile);

    if (this.tags.length > 0 && !!this.docDetails) {
      await lastValueFrom(this._fileService.indexFile(updatedFile.id));
    } else {
      await lastValueFrom(this._fileService.unindexFile(updatedFile.id));
    }

    this.goBack();
  }

  runOcr(ocrAction: string) {
    const dialogRef = this.dialog?.open(ModalComponent, {
      width: '1072',
      data: {
        action: ocrAction,
        fileId: this.file.id,
        fileName: this.file.name,
      },
    });

    dialogRef?.afterClosed()?.subscribe(result => {
      this.tags = this._fileActionService.file.tags;
      if (result) {
        this.ocrResult = result.text;
        this.file = result.file;
        this.tags = this.file?.tags || [];
      }
    });
  }

  onBreadcrumbBack(data: any) {
    if (data.id === '') {
      this.breadcrumbs = [];
      this._breadcrumbsService.emptyBreadcrumb();
    } else {
      const loc = this.breadcrumbs.findIndex(datum => datum.id === data.id);
      this._breadcrumbsService.backBreadcrumb(loc);
      this._fileService.getAllFolderFiles(data.id).subscribe(({ data }) => {
        const format = this._fileService.formatFilesAndFolderData(data);
        this._fileService.setFilesAndFolderAsync(format);
      });
    }

    this.goBack();
  }
  removeTag(tag: any) {
    console.log({ tag });
    this.tags = this.tags.filter(data => {
      if (tag.name == 'ocr' || data != tag) return data;
    });
  }

  publishFile() {
    this.isPublished = !this.isPublished;
    const data = {
      type: 'publish',
      text: '',
    };

    this._fileService
      .publishFile(this.fileId, this.isPublished)
      .subscribe(i => {
        if (this.isPublished) {
          data['text'] = 'File published';
        } else {
          data['text'] = 'File unpublished';
        }

        this._snackBar.openFromComponent(SnackbarComponent, {
          duration: 800,
          data: data,
        });
        this.goBack();
      });
  }
}
